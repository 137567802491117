<template>
  <div>
    <div v-if="!sent">
      <v-alert  
  type="info" dense class="caption"
>
Confirm this sign-in once, by sending a sign-in link to your Email address.
</v-alert>

       <v-text-field class="ma-4" v-model="email" required placeholder="Email" name="email" type="text"
       :append-outer-icon="email ? 'mdi-send' : 'mdi-send-outline'"
       @click:append-outer="sent = true, sendMail()" @keydown.enter="sent = true, sendMail()" 
        />
     </div>
    <div v-else>
      <v-alert  
  type="info"  class="caption"
>
Confirm this sign-in once, by clicking the link sent to <span v-if="this.email"> {{email}},</span> <span v-else> your Google account's email, </span >on this device.
</v-alert> </div>
  </div>
</template>

<script>

import { auth } from "./firebase/db.js";



export default {

  data() {
    return {

sent: false,
    email: "",


      
    };
  },
  

  computed: {
    
  
  },
  methods: {
    async sendMail() {
      this.loading = true;
      try {
        console.log(this.email)
 let actionCodeSettings = {
          url: process.env.VUE_APP_URL,
          // This must be true.
          handleCodeInApp: true,
          /*iOS: {
    bundleId: 'com.example.ios'
  },
  android: {
    packageName: 'com.example.android',
    installApp: true,
    minimumVersion: '12'
  },*/
          //dynamicLinkDomain: 'marketplace.trustlesshub.com.page.link'
        };

        auth
          .sendSignInLinkToEmail(
            this.email,
            actionCodeSettings
          )
          .then(() => {
            // The link was successfully sent. Inform the user.
            // Save the email locally so you don't need to ask the user for it again
            // if they open the link on the same device.
          
            window.localStorage.setItem("emailForSignIn", this.email);
            //alert("Confirm by clicking the email link on your device")
            // ...
          })
          .catch((error) => {
            alert(error)
            var errorCode = error.code;
            var errorMessage = error.message;
            // ...
          });
      } catch (error) {
         alert(error)
        this.loading = false;
        console.error(error, "caught");
      }
    },
   
  },
  

};
</script>

